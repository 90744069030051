import apiClient from "../client";
import { categories } from "../../../types/home/homeTypes";

export const fetchCategories = async (foodcourtId: string): Promise<categories> => {
  try {
    const response = await apiClient.get<categories>(`/menu/customer/categories/${foodcourtId}`);
    return response.data;
  }
  catch(error){
    console.error(error)
    throw error;
  }
  };