import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
const OrderSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const idArray = location.pathname.split('/');
    const categoryId = idArray[1];
    const id = idArray[3];
    return (
        <div className='processing_div'>
            <div className="confirmation-container">
                <CheckCircleRoundedIcon sx={{fontSize:"90px",color:"green",animation: "scaleIn 0.5s ease-in-out"}}/>
                <h1 className="title">Order Confirmed</h1>
                <button onClick={() => { navigate(`/${categoryId}/orders/OrderDetails/${id}`, { state: { id: id } }) }}>
                    Track Order
                </button>
            </div>
        </div>
    )
}

export default OrderSuccess;