import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import CartCard from "../../components/cards/CartCard";
import CouponSearch from "../../components/misc/CouponSearch";
import Header from "../../components/misc/Header";
import { useCart } from "../../context/cart/carttcontext";
import './cart.css';
import Loader from '../../components/misc/Loader';
import { createOrder } from '../../services/api/cart/cartServices';
import { useCategories } from '../../hooks/home/usehome';
import { useMenu } from '../../hooks/menu/useMenu';
import empty from '../../assets/icons/empty-cart.jpg';
const Cart = () => {
    const { cart, dispatch } = useCart();
    const { categoryId } = useParams<{ categoryId: string }>();
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');
    const [loading, setLoading] = useState<boolean>(false);
    const { data: menu, isLoading, error } = useMenu(String(categoryId));
    // console.log("menu",menu);
    const menuItemIds = menu?.categories?.flatMap((category: any) =>
        category.menuItems.map((menuItem: any) => menuItem.menuItemId)
    );
    // Filter cart items that belong to the current food court
    const validCartItems = cart.filter(item => menuItemIds?.includes(item.menuItemId));
    const data = validCartItems.map(item => ({
        menuItemId: item.menuItemId,
        priority: 1,
        quantity: item.quantity
    }));

    const calculateTotals = () => {
        const subtotal = validCartItems.reduce((acc: number, item: { price: number; quantity: number; }) => acc + (item.price * item.quantity), 0);
        const gst = subtotal * 0.00;
        const total = subtotal + gst;
        return { subtotal, gst, total };
    };

    const handleClick = () => {
        navigate(`/${categoryId}/menu`);
    };

    const handlePayment = async () => {
        if (!token) {
            navigate('/otp', { state: { loc: "cart", id: categoryId } });
            return;
        }

        setLoading(true);
        try {
            const createData = await createOrder(categoryId!, data, token!);

            if (createData.paymentUrl) {
                dispatch({ type: 'CLEAR_CART' });
                window.location.href = createData.paymentUrl;
            } else {
                console.error('Failed to initiate payment');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error creating order:', error);
            setLoading(false);
        }
    };

    const { subtotal, gst, total } = calculateTotals();

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    <Loader />
                </div>
            )}
            {validCartItems.length > 0 ?
                <div className="mycart" style={{ backgroundColor: "#F5F5F8", height: "fit-content", minHeight: "90vh", paddingBottom: "20px", marginTop: "70px", paddingTop: "10px" }}>
                    <Header title={"My Cart"} shown={false} onFilterClick={undefined} back={true} />
                    <div style={{ margin: "5px" }}>
                        {validCartItems.map((item, index) => (
                            <CartCard
                                key={index}
                                title={item.title}
                                subtext={"Choose your options"}
                                price={item.price}
                                imageUrl={item.imageUrl}
                                quantity={item.quantity}
                            />
                        ))}
                    </div>
                    <div style={{ marginLeft: "10px", marginTop: "30px", borderRadius: "30px", backgroundColor: "#DCDCDC", width: "95%", border: "1px solid #F9F9F9" }}>
                        <CouponSearch />
                    </div>
                    <div style={{ marginLeft: "20px", marginRight: "20px", display: "flex", flexDirection: "column", gap: "15px", marginTop: "20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", fontWeight: "400" }}>Subtotal</h3>
                            <h3 style={{ fontSize: "16px", fontWeight: "600" }}>₹{subtotal.toFixed(2)}</h3>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", fontWeight: "400" }}>GST</h3>
                            <h3 style={{ fontSize: "16px", fontWeight: "600" }}>₹{gst.toFixed(2)}</h3>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontSize: "14px", fontWeight: "700" }}>Total</h3>
                            <h3 style={{ fontSize: "16px", fontWeight: "700" }}>₹{subtotal.toFixed(2)}</h3>
                        </div>
                    </div>
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "30px" }}>
                        <div onClick={handlePayment} style={{ width: "80%", border: "1px solid #1E3966 ", height: "50px", borderRadius: "60px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#1E3966", color: "white", cursor: "pointer" }}>
                            <p style={{ fontSize: "18px", }}>Order Now</p>
                        </div>
                    </div>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                    <Header title={"My Cart"} shown={false} onFilterClick={undefined} back={true} />
                    <div className='cart-items'>
                        <div style={{alignItems:"center",display:"flex",flexDirection:"column"}}>
                            <img src={empty} width={200} height={200} />
                            <p style={{ color: "#333", fontSize: "18px" }}>Your cart is empty</p>
                        </div>
                        <div className='navigation-text'>
                            <p style={{ textAlign: "center", width: "200px" }}>
                                Looks like you haven't made your choice yet...
                            </p>
                        </div>
                        <button  style={{padding:"6px 17px", marginTop:"10px",border:"1px solid #1E3966",backgroundColor:"#1E3966",borderRadius:"3px",color:"White",letterSpacing:"0.7px",lineHeight:"20px"}} onClick={handleClick}>Go to Menu</button>
                    </div>
                </div >
            }
        </>
    );
};

export default Cart;
