import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import back from '../../assets/icons/back2.png';
import '../Styles/search.css';
import { useCategories } from '../../hooks/home/usehome';
import { category } from '../../types/home/homeTypes';
import Loader from './Loader';

const Search = () => {
  const navigate = useNavigate();
  const foodid=localStorage.getItem("foodcourtID");
  const { data, isLoading, error } = useCategories(String(foodid));
  const [searchTerm, setSearchTerm] = useState('');
  // const id = 2;
  const filteredCategories = data?.categories?.filter(item =>
    item.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const { categoryId } = useParams<{ categoryId: string }>();
  return (

    <div className='main_search'>
      <div className='search2' style={{ border: "1px solid #DCDCDC" }}>
        <img src={back} width={18} height={18} onClick={() => navigate(-1)} alt="Back" />
        <input
          type='text'
          placeholder='Search for food...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {isLoading ? (
        <div style={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className='search-items'>
          {filteredCategories ?
            filteredCategories.length > 0 ? filteredCategories?.map((item: category) => (
              <div
                key={item.categoryId} // Using unique categoryId for the key
                className='each-search-item'
                onClick={() => navigate(`/${categoryId}/menu`, { state: { value: item.categoryName } })}
              >
                <img src={item.categoryImage} alt={item.categoryName} />
                <h4>{item.categoryName}</h4>
              </div>
            )) : (
              <div>No categories found.</div>
            )
            : <>No items to show</>}
        </div>
      )}
    </div>
  );
}

export default Search;
