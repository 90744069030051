import { useQuery } from '@tanstack/react-query';
import { checkStatus } from '../../services/api/cart/checkStatus';
interface status{
    id:string;
    status:string;
}
export const useStatus = (id:string,token:string) => { 
    const { data, error, isLoading } = useQuery<status, Error>({
      queryKey: ['status', id],
      queryFn: () => checkStatus(id,token),
      refetchOnWindowFocus: false
    });

    return {
        data: data,
        error,
        isLoading
    }
}