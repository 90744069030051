import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/misc/Loader';

const LoadingPag: React.FC = () => {
    const location = useLocation();
    const paymentUrl = location.state?.paymentUrl;

    useEffect(() => {
        if (paymentUrl) {
            setTimeout(() => {
                window.location.href = paymentUrl;
            }, 1000);
        }
    }, [paymentUrl]);

    return (
        <div className="loading-overlay" style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </div>
    );
}

export default LoadingPag;
