import { useQuery } from '@tanstack/react-query';
import { fetchMenu } from '../../services/api/menu/menuServices';
import Menu from '../../types/menu/menuTypes';

export const useMenu = (id: String) => { 
    const { data, error, isLoading } = useQuery<Menu, Error>({
      queryKey: ['menu', id],
      queryFn: () => fetchMenu(id),
      refetchOnWindowFocus: false
    });

    return {
        data: data,
        error,
        isLoading
    }
}