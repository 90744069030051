import { CircularProgress } from '@mui/material';
import React from 'react';
import logo from '../../assets/icons/logo.svg';
import '../Styles/Loader.css'; 

const Loader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <CircularProgress className="spinner" size={42} />
        <img src={logo} alt="logo" className="logo" />
      </div>
    </div>
  );
};

export default Loader;