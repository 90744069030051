import { CircularProgress } from '@mui/material';
import React from 'react';
import logo from '../../assets/icons/logo.svg';
import '../Styles/Loader.css'; 

const OrderLoader: React.FC = () => {
  return (
    <div className="loader-container" style={{height:"10vh"}}>
      <div className="loader">
        <CircularProgress className="spinner" size={30} />
        <img src={logo} alt="logo" className="logo" style={{width:"25px",height:"25px",paddingRight:2}}/>
      </div>
    </div>
  );
};

export default OrderLoader;