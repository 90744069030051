import Menu from '../../assets/icons/Menu.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useCategories } from '../../hooks/home/usehome';
import { useMenu } from '../../hooks/menu/useMenu';
const MenuItems = () => {
    const navigate = useNavigate();
    // const id = 2;
    const { categoryId } = useParams<{ categoryId: string }>();
    const foodID=localStorage.getItem("foodcourtID");
    const { data, isLoading, error } = useMenu(String(foodID));
    return (
        <div className='menu-items'>
            <div className='menu-list'>
                <img src={Menu} alt="" width={80} height={80} />
                <div className='actual-menu'>
                    {data?.categories?.filter(item => item.menuItems.length > 0).map((item) => {
                        return (
                            <div
                                className='each-item'
                                onClick={() => navigate(`/${categoryId}/menu`, { state: { value: item.categoryName } })}
                                key={item.categoryId}  // Always use unique keys for list items
                            >
                                <p >{item.categoryName}</p>
                                <p>{item.menuItems.length}</p>
                            </div>
                        );
                    })}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
                        <button
                            style={{ border: "none", backgroundColor: "#1E3966", padding: "10px", width: "100px", borderRadius: "20px", color: "white" }}
                            onClick={() => navigate(-1)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuItems