import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchEachOrder, fetchOrders } from '../../services/api/orders/orderServices';
import { ORDER, ORDERS } from '../../types/orders/orderTypes';

export const useOrder = (token: string, pageNumber: number, pageSize: number) => {
    const queryClient = useQueryClient();
  
    const { data, error, isLoading } = useQuery<ORDERS, Error>({
      queryKey: ['orders', token, pageNumber], // Include pageNumber in queryKey
      queryFn: () => fetchOrders(token, pageNumber, pageSize),
      enabled: !!token,
      refetchOnWindowFocus: false,
    });
  
    return {
      data: data,
      isLoading,
      error,
    };
  };
export const useEachOrder=(token:string,id:string)=>{
    const {data,isLoading,error}= useQuery<ORDER,Error>({
        queryKey:["order",id],
        queryFn:()=>fetchEachOrder(token,id),
        enabled:!!token,
        refetchOnWindowFocus: false
    });
    return{
        data:data,
        isLoading,
        error
    }
}