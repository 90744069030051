import right_arrow from '../../assets/icons/right_arrow.png';
const CouponSearch = () => {
  return (
    <div className='coupon'>
        <input type="text" placeholder='Enter Coupon code'/>
        <img src={right_arrow} alt="" width={55} height={55}/>
    </div>
  )
}

export default CouponSearch