import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { createFailedOrder } from '../../services/api/cart/failedServices';
const OrderFailed: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const idArray = location.pathname.split('/');
    const categoryId = idArray[1];
    const id = idArray[3];
    const token = localStorage.getItem('authToken');
    console.log("token",token);
    const handleretry=async ()=>{
        const data=await createFailedOrder(id);
        console.log("data",data);
        if (data?.paymentUrl) {
            window.location.href= data?.paymentUrl ;
        }
    }                   
    return (
        <div className='processing_div' style={{backgroundColor:"red"}}>
            <div className="confirmation-container" style={{backgroundColor:"red"}}>
                <CancelRoundedIcon sx={{fontSize:"90px",color:"white",animation: "scaleIn 0.5s ease-in-out"}}/>
                <h1 className="title">Order Failed</h1>
                <button onClick={handleretry} style={{backgroundColor:"orange",color:"white"}}>
                    Retry
                </button>
            </div>
        </div>
    )
}

export default OrderFailed;