import React, { useEffect, useState } from 'react'
import completed from '../../assets/icons/completed.png';
import started from '../../assets/icons/started.png';
import CircularProgress from '@mui/material/CircularProgress';
import not_started from '../../assets/icons/not_started.png';
import Header from '../../components/misc/Header';
import OrderItems from './OrderItems';
import { useEachOrder } from '../../hooks/orders/useOrder';
import { ORDER, ORDERITEMS } from '../../types/orders/orderTypes';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/misc/Loader';
const OrderDetails = () => {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const token = localStorage.getItem('authToken');
  const location = useLocation();
  const { id } = useParams<{ id: string }>(); // Explicitly specify that id should be a string
  console.log("orderid", id);
  const { data, isLoading, error } = useEachOrder(token || '', id || '');
  const statuses = ['CONFIRMED', 'PREPARING', 'READY_FOR_DELIVERY'];
  const orderid = data?.uniqueOrderid;
  const [currentStatus, setcurrent] = useState('CONFIRMED');
  useEffect(() => {
    if (data && data.items.length > 0) {
      // Example: Set status from the first item, or find specific conditions
      setcurrent(data.items[0].status);
    }
  }, [data]);
  const getIconForStep = (step: string) => {
    if (statuses.indexOf(step) < statuses.indexOf(currentStatus)) {
      return completed; // Path to the completed icon image
    } else if (step === currentStatus) {
      return started; // Path to the started/current icon image
    } else {
      return not_started; // Path to the not started icon image
    }
  };
  const renderStep = (step: string) => {
    const isCurrent = step === currentStatus;
    return (
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "row" }}>
        {isCurrent ? (
          <div style={{marginBottom:"1px"}}>
            <img src={getIconForStep(step)} alt={step} style={{ width: 23, height: 23, display: 'block', position: 'relative', zIndex: 2 }} />
            <CircularProgress
              variant="determinate"
              value={progress}
              size={27}
              style={{
                color: '#1B9FCE',
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 1,
                transform: 'translate(-50%, -50%)'
              }}
            />
          </div>
        ) : (
          <img src={getIconForStep(step)} alt={step} width={25} height={25} />
        )}
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div style={{ marginTop: "70px" }}>
          <Header title={"Order Details"} shown={false} onFilterClick={undefined} back={false} />
          <div className='orderdetails'>
            <div className='order-card'>
              <div className='order-id' >
                <h3 style={{ marginBottom: "10px", whiteSpace: 'nowrap' }}>Order #{orderid}</h3>
                <p>{data?.createdAt}</p>
              </div>
              <div className='order-status'>
                <h3 style={{ whiteSpace: 'nowrap' }}>Order Status</h3>
                <div className='order-status-inner-div'>
                  {statuses.map(step => (
                    < div style={{ display: "flex", flexDirection: "column" }}>
                      <div key={step} style={{ margin: '0 10px' }}>
                        {renderStep(step)}
                      </div>
                      <p style={{ fontSize: "10px", paddingTop: "5px", color: currentStatus===step ? "#1E3966" : "black",fontWeight:600 }}>
                        {step === "READY_FOR_DELIVERY" ? "COMPLETED" : step}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className='order-items'>
                {data && data.items.map((detail: ORDERITEMS) => {
                  return (
                    <OrderItems key={detail.itemId} imagepath={detail.imageUrl} title={detail.menuItemName} price={detail.price} status={detail.status} quantity={detail.quantity} />
                  )
                })
                }
              </div>
              <div>
                <div style={{ marginLeft: "20px", marginRight: "20px", display: "flex", flexDirection: "column", gap: "15px", marginTop: "20px", marginBottom: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3 style={{ fontSize: "14px", fontWeight: "400" }}>Subtotal</h3>
                    <h3 style={{ fontSize: "16px", fontWeight: "600" }}>₹{data?.totalPrice.toFixed(2) ?? ''}</h3>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3 style={{ fontSize: "14px", fontWeight: "400" }}>GST</h3>
                    <h3 style={{ fontSize: "16px", fontWeight: "600" }}>₹00.00</h3>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3 style={{ fontSize: "14px", fontWeight: "700" }}>Total</h3>
                    <h3 style={{ fontSize: "16px", fontWeight: "700" }}>₹{data?.totalPrice.toFixed(2) ?? ''}</h3>
                  </div>
                </div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px", padding: "10px" }}>
                  <button style={{ padding: "5px", border: "none", color: "white", backgroundColor: "#1B9FCE", borderRadius: "4px", }}>Re-order</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrderDetails