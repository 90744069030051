import React from 'react';
import Header from '../../components/misc/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useRating } from '../../hooks/rating/useRating';
import Loader from '../../components/misc/Loader';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
const RatingScore: React.FC = () => {
    const navigate = useNavigate();
    const { id, categoryId } = useParams<{ id: string; categoryId: string }>();
    const { data, isLoading, error } = useRating(String(id));

    // Show loader while data is loading
    if (isLoading) return <Loader />;

    // Ensure data is defined before accessing its properties
    if (!data || data.length === 0) {
        return <p>No feedback data available.</p>; // Handle case when data is undefined or empty
    }

    // Extract overall rating and comments from the first item
    const overallRating = data[0]?.rating; // Overall rating from the first object
    const overallComment = data[0]?.comment; // Comment from the first object

    // Extract item ratings from the rest of the data
    const itemRatings = data.slice(1).map(item => ({
        name: item.orderItemId || 'Unknown Item', // Using orderItemId as item name
        score: item.rating
    }));

    return (
        <>
            <Header title="Feedback" shown={false} onFilterClick={undefined} back={true} />
            <div style={{ marginTop: "70px", padding: '20px', backgroundColor: '#f0f0f0', height: "90vh" }}>
                <div style={{
                    padding: '30px',
                    backgroundColor: '#ffffff', // White background for the main content
                    borderRadius: '12px', // Rounded corners
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)', // Soft shadow for depth
                    margin: '20px 0',
                    textAlign: "center"
                }}>
                    <h3 style={{ fontSize: '24px', fontWeight: '600', color: '#333' }}>Overall Rating Score</h3>
                    <h2 style={{ fontSize: '30px', fontWeight: 'bold', color: '#4CAF50' }}>{overallRating?.toFixed(2)}</h2>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    margin: '10px 0',
                    padding: "10px",
                    alignItems: "flex-start" // Align items to the start
                }}>
                    {itemRatings.map((item, index) => (
                        <div key={index} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '14px 18px',
                            backgroundColor: "#ffffff",
                            marginBottom: "10px",
                            borderRadius: "8px", // Rounded corners
                            width: '100%',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for item rows
                            border: "1px solid #e0e0e0" // Light border for better separation
                        }}>
                            <span style={{ fontWeight: '500', color: '#333', fontSize: '16px' }}>{item.name}</span>
                            <span style={{ fontWeight: 'bold', 
                                color: item.score >= 4 ? "#03C03C" : item.score <2 ? "#E32636" : "#FFA500",
                                fontSize: '16px',marginRight:5 }}>{item.score.toFixed(2)}</span>
                            {item.score >= 4 ? (
                                <RecommendIcon sx={{ color: '#03C03C' }} /> // Show green icon for score >= 4
                            ) : item.score < 2 ? (
                                <ThumbDownAltIcon sx={{ color: '#E32636' }} /> // Show red icon for score == 1
                            ) : (
                                <RecommendIcon sx={{ color: '#FFA500' }} /> // Show orange icon for score 2 or 3
                            )}
                        </div>
                    ))}
                </div>

                {overallComment !== null && (
                    <div style={{ padding: "20px" }}>
                        <textarea
                            style={{
                                width: '100%',
                                height: '120px',
                                padding: '15px',
                                borderRadius: '6px',
                                border: '0px solid #ccc',
                                resize: 'none',
                                backgroundColor: '#f9f9f9',
                                color: 'black',
                                outline: "none",
                                fontSize: '16px', // Font size for readability
                                boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)', // Inner shadow for depth
                                textTransform: "capitalize"
                            }}
                            value={overallComment || "No comments provided."} // Fallback if comment is empty
                            readOnly
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default RatingScore;
