// AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AuthContextType } from '../../types/auth/AuthTypes';
  // Ensure the path is correct

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // Default to false
    const [sessionExpired, setSessionExpired] = useState(false);
  
    const logout = () => {
      console.log("Logging out, setting session expired");
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setSessionExpired(true);
    };
  
    const login = () => {  // Method to set isAuthenticated to true
      setIsAuthenticated(true);
    };
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, sessionExpired, setSessionExpired, logout, login }}>
        {children}
      </AuthContext.Provider>
    );
  };

