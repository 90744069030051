import React from 'react';
import './NotificationPermission.css'; // You can style this component with CSS

interface NotificationPermissionProps {
    onAllow: () => void;
    onDecline: () => void;
}

const NotificationPermission: React.FC<NotificationPermissionProps> = ({ onAllow, onDecline }) => {
    return (
        <div className="notification-permission-backdrop">
            <div className="notification-permission-modal">
                <h2>Enable Notifications</h2>
                <p>Would you like to receive notifications for updates and alerts?</p>
                <div className="notification-permission-buttons">
                    <button onClick={onAllow} className="allow-button">Allow</button>
                    <button onClick={onDecline} className="decline-button">Decline</button>
                </div>
            </div>
        </div>
    );
};

export default NotificationPermission;
