import React, { useEffect, useState } from 'react';
import './Order.css';
import OrderCard from '../../components/cards/OrderCard';
import AlertDialogSlide from '../../components/misc/BottomDrawer';
import Header from '../../components/misc/Header';
import { useOrder } from '../../hooks/orders/useOrder';
import { ORDER } from '../../types/orders/orderTypes';
import { useNavigate, useParams } from 'react-router-dom';
import order from '../../assets/images/no_order.png';
import Loader from '../../components/misc/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import OrderLoader from '../../components/misc/OrderLoader';
import OrderStatusCard from '../../components/cards/OrdeStatusCard';
import Footer from '../../components/misc/Footer';

const Orders = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(1);  // Starting page number is 1
  const [orders, setOrders] = useState<ORDER[]>([]);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');
  const { data, isLoading, error } = useOrder(token || '', page, 10);
  const { categoryId } = useParams<{ categoryId: string }>();

  useEffect(() => {
    if (token === null) {
      navigate("/otp");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (data) {
      setOrders(prevOrders => [...prevOrders, ...data]);
    }
  }, [data]);

  const fetchMoreData = () => {
    setPage(prevPage => {
      const nextPage = prevPage + 1;
      // refetch({ page: nextPage });  // Pass the next page to refetch
      return nextPage;
    });
  };

  return (
    <>
      {isLoading && page === 1 ? (
        <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className='orders'>
          <Header title="My Orders" shown={true} onFilterClick={handleOpenDialog} back={true} />
          <div style={{ marginBottom: "50px", paddingBottom: "10px", marginTop: "60px", paddingTop: "5px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {orders?.length > 0 ? (
              <InfiniteScroll
                dataLength={orders?.length}
                next={fetchMoreData}
                hasMore={!isLoading && data?.length === 10} // Assuming you fetch 10 items per page
                loader={<OrderLoader />}
                endMessage={
                  <p style={{ textAlign: 'center', marginBottom: 10 }}>
                    <b> </b>
                  </p>
                }
              >
                {orders?.map((order: ORDER) => (
                  <OrderCard
                    key={order.orderId}
                    orderid={order.orderId}
                    timestamp={order.createdAt}
                    status={order.status}
                    totalprice={order.totalPrice}
                    items={order.items}
                    uniqueOrderid={order.uniqueOrderid}
                    overallFeedbackScore={order.overallFeedbackScore}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", flexDirection: "column" }}>
                <img src={order} alt={" "} width={80} />
                No order yet
                <button style={{ padding: "10px 20px", border: "1px solid #1E3966", backgroundColor: "white", borderRadius: "6px", color: "#1E3966", marginTop: "10px" }} onClick={() => { navigate(`/${categoryId}/menu`) }}>Browse Items</button>
              </div>
            )}
          </div>
          <div style={{ paddingInline: "20px", paddingBottom: "150px" }}>
            <Footer />
          </div>
          {localStorage.getItem("authToken") &&
            <OrderStatusCard bottom='54px' />
          }

          <AlertDialogSlide open={openDialog} handleClose={handleCloseDialog} />
        </div>
      )}
    </>
  );
};

export default Orders;
