import React, { useEffect, useState } from 'react';
import { useCart } from '../../context/cart/carttcontext';
import { CartItem } from '../../types/cart/cartTypes';
import placeholder from '../../assets/images/placeholder.png';

interface Menucard {
  imageUrl: string;
  title: string;
  price: number;
  menuItemId: string;
  available: boolean;
}

const MenuCard: React.FC<Menucard> = ({ imageUrl, title, price, menuItemId, available }) => {
  const { cart, dispatch } = useCart();
  const [count, setCount] = useState(0);
  const [isAdded, setIsAdded] = useState(false);
  const [imageSrc, setImageSrc] = useState(imageUrl);

  useEffect(() => {
    const existingItem = cart.find(item => item.title === title);
    if (existingItem) {
      setIsAdded(true);
      setCount(existingItem.quantity);
    } else {
      setIsAdded(false);
      setCount(0);
    }
  }, [cart, title]);

  const handleError = () => {
    setImageSrc(placeholder);
  };

  const handleAddClick = () => {
    if (!isAdded && available) {
      dispatch({ type: 'ADD_ITEM', item: { title, price, imageUrl, quantity: 1, menuItemId } });
    }
  };

  const handleIncrease = () => {
    dispatch({ type: 'INCREASE', title });
  };

  const handleDecrease = () => {
    if (count > 1) {
      dispatch({ type: 'DECREASE', title });
    } else {
      dispatch({ type: 'REMOVE_ITEM', title });
      setIsAdded(false);
    }
  };

  return (
    <div className='menu-cards'>
      <div>
        <img
          src={imageSrc}
          alt={title}
          style={{
            borderRadius: "5px",
            objectFit: "cover",
            objectPosition: "center",
            width: "70px",
            height: "60px"
          }}
          onError={handleError}
        />
      </div>
      <div className='menu-card'>
        <h2>{title}</h2>
        <div className='price-button'>
          <p style={{ fontSize: "16px", fontWeight: "700" }}>₹{price.toFixed(2)}</p>
          {!available ? (
            <div style={{border:"1px solid gray",padding:"3px",borderRadius:"3px" }}><p style={{color: 'gray', fontWeight: '500',fontSize:"14px"}}>Not Available</p></div>
          ) : (
            !isAdded ? (
              <button onClick={handleAddClick}>Add</button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button onClick={handleDecrease} style={{ width: "20px", height: "20px", backgroundColor: "#1E3966" }}>-</button>
                <span style={{ margin: '0 10px' }}>{count}</span>
                <button onClick={handleIncrease} style={{ width: "20px", height: "20px", backgroundColor: "#1E3966" }}>+</button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
