import React from 'react'
import greylogo from '../../assets/icons/grey_bg.png';
const Footer: React.FC = () => {
    return (
        <div style={{marginTop:"150px",marginBottom:"90px",width:"300px",padding:"10px",paddingInline:"1px"}}>
            <img src={greylogo}  style={{width:"50px"}}/>
            <h1 style={{fontSize:"16px",color:"gray",fontWeight:500}}>Crafted with <span style={{color:"#1E3966"}}>❤</span>  in Hyderabad,India</h1>
        </div>
    )
}

export default Footer