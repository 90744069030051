import { ORDER, ORDERS } from "../../../types/orders/orderTypes";
import apiClient from "../client";

export const fetchOrders = async (token: string,pageNumber:number,pageSize:number): Promise<ORDERS> => {
  try {
    const response = await apiClient.get<ORDERS>(`/order/customer?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw new Error("Failed to fetch orders. Please try again later.");
  }
};

export const fetchEachOrder = async (token: string, id: string): Promise<ORDER> => {
  try {
    const response = await apiClient.get<ORDER>(`/order/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching order with id ${id}:`, error);
    throw new Error(`Failed to fetch order ${id}. Please try again later.`);
  }
};
