// utils.ts
export const isMobileDevice = (): boolean => {
    const userAgent = navigator.userAgent;
  
    // iOS detection
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return true;
    }
  
    // Android detection
    if (/Android/i.test(userAgent)) {
      return true;
    }
  
    // Other mobile devices detection
    if (/Opera Mini|IEMobile|WPDesktop/i.test(userAgent)) {
      return true;
    }
  
    return false;
  };
  