import './Order.css';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
interface orderItems {
  imagepath: string;
  title: string;
  price: number;
  quantity: number;
  status: string;
}
const labels: { [index: string]: string } = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
const OrderItems: React.FC<orderItems> = ({ imagepath, title, price, status, quantity }) => {
  // Determine the button's background color and text based on the status
  const getStatusStyles = () => {
    switch (status) {
      case "CONFIRMED":
        return { backgroundColor: "#1E3966", text: "CONFIRMED" };
      case "PREPARING":
        return { backgroundColor: "orange", text: "PREPARING" };
      case "READY_FOR_DELIVERY":
        return { backgroundColor: "green", text: "COMPLETED" };
      case "PAYMENT_FAILED":
        return { backgroundColor: "grey", text: "PAYMENT FAILED" };
      default:
        return { backgroundColor: "grey", text: status };
    }
  };
  const [value, setValue] = React.useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);
  const { backgroundColor, text } = getStatusStyles();

  return (
    <div className='order-cards'>
      <div style={{ width: "30%", height: "80px" }}>
        <img src={imagepath} alt="" width="90%" height="85%" style={{ borderRadius: "8px" }} />
      </div>
      <div className='order-items-card'>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
          <h2 style={{ textTransform: "capitalize", maxWidth: "130px", textWrap: "wrap" }}>{title}</h2>
          <button style={{ backgroundColor, border: "none", color: "white", padding: "3px", borderRadius: "3px", fontSize: "10px", textTransform: "uppercase", fontWeight: "550", paddingInline: "6px", textAlign: "center", paddingTop: 4, letterSpacing: 0.5 }}>
            {text}
          </button>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", width: "90%" }}>
          <p style={{ color: "#1B9FCE", fontSize: "16px", fontWeight: "700" }}>₹ {price ? price.toFixed(2) : "0.00"}</p>
          <p style={{ color: "#1E3966", fontSize: "12px", fontWeight: 700 }}>Qty: {quantity}</p>
        </div>
        <div style={{ marginBlock: "5px",display:"flex",justifyContent:"flex-start" }}>
         
        </div>
      </div>
    </div>
  );
}

export default OrderItems;
