import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import foodimage from "../../assets/images/mainlogo.png";
import { useCode, useResend } from '../../hooks/otp/useOtp';
import { useCategories } from '../../hooks/home/usehome';
import axios from 'axios';
import { useCart } from '../../context/cart/carttcontext';
import Loader from '../../components/misc/Loader';
import NotificationPermission from '../../components/notifications/NotificationPermission';
import { useAuth } from '../../context/auth/Authcontext';
import { resendOtp } from '../../services/api/otp/otpServices';

const VerifyOtp: React.FC = () => {
    const { login } = useAuth();
    const [otp, setOtp] = useState<string>("");
    const [timer, setTimer] = useState<number>(30);
    const [canResend, setCanResend] = useState<boolean>(false);
    const [verifyTrigger, setVerifyTrigger] = useState<boolean>(false);
    const [inputError, setInputError] = useState<boolean>(false);
    const [correct, setCorrect] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [showNotificationPermission, setShowNotificationPermission] = useState<boolean>(false); // State to manage the notification permission popup
    const phoneNumber = location.state?.phone || '';
    const loc = location.state?.from?.pathname;
    console.log("pathname:",loc);
    const { code, isLoading, error } = useCode(phoneNumber, otp, verifyTrigger);
    const { resend, isLoading: resendLoading, error: resendError } = useResend(phoneNumber, canResend);
    const foodid = localStorage.getItem("foodcourtID");
    const { data: categories } = useCategories(String(foodid));
    const foodcourtID = categories?.categories?.[0]?.foodCourtId;


    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then(registration => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }, []);

    useEffect(() => {
        if ('OTPCredential' in window) {
            const abortController = new AbortController();
            navigator.credentials.get({
                otp: { transport: ['sms'] },
                signal: abortController.signal
            } as CredentialRequestOptions & { otp: { transport: string[] } })
                .then((otpCredential: any) => {
                    console.log("Auto-filled OTP:", otpCredential.code);
                    setOtp(otpCredential.code);
                })
                .catch((error) => console.error("Error fetching OTP: ", error));

            return () => abortController.abort();
        }
    }, []);

    useEffect(() => {
        if (otp.length === 6) {
            setVerifyTrigger(true);
        }
    }, [otp]);

    useEffect(() => {
        if (code) {
            setCorrect(true);
            login();
            if (code?.token) {
                localStorage.setItem('authToken', code?.token);
                console.log("Token added to localStorage");
                // setShowNotificationPermission(true);
                if (loc && !code?.newUser && loc!=='/otp') {
                    // handlePayment();
                        navigate(loc,{ replace: true })
                } else {
                    navigate(`/${foodcourtID}/home`, {
                        state: { user: code?.newUser, loc: loc },
                        replace: true,
                      });
                }
            }
        } else if (verifyTrigger && !isLoading && code === undefined) {
            setInputError(true);
            setVerifyTrigger(false);
        }
    }, [code, isLoading, navigate, verifyTrigger]);


    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const subscription = await subscribeUserToPush();
            if (subscription) {
                await sendSubscriptionToServer(subscription);
            }
        } else {
            console.log('Notification permission denied.');
        }
        if (loc) {
            handlePayment();
        } else {
            setTimeout(() => navigate(`/${foodcourtID}/home`, { state: { user: code?.newUser } }), 2000);
        }
    };

    const subscribeUserToPush = async (): Promise<PushSubscription | null> => {
        try {
            const swRegistration = await navigator.serviceWorker.ready;
            const subscription = await swRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array('BBS6De8aocze2igvwozOdv-i27rEjgkpVXHVNY3eEJgOBXA9sQM6eOQIkim4kUGyt1HsY8vAKECzM3C5X9E9aGc')  // Replace with your VAPID public key
            });
            return subscription;
        } catch (error) {
            console.error('Failed to subscribe the user: ', error);
            return null;
        }
    };

    const sendSubscriptionToServer = async (subscription: PushSubscription) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                await axios.post('https://dev-api.sermyble.com/notifications/notifications/subscribe', {
                    endpoint: subscription.endpoint,
                    p256dhKey: arrayBufferToBase64(subscription.getKey('p256dh')!),
                    authKey: arrayBufferToBase64(subscription.getKey('auth')!),
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log('User subscribed successfully');
            } catch (error) {
                console.error('Error subscribing user to notifications: ', error);
            }
        }
    };
    const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };



    const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };


    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => setTimer(prev => prev - 1), 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    const handleResendCode =async () => {
        setTimer(30);
        // call api
        await resendOtp(phoneNumber);
    };

    const { cart, dispatch } = useCart();
    const id = location.state?.id;

    const data = cart.map(item => ({
        menuItemId: item.menuItemId,
        priority: 1,
        quantity: item.quantity
    }));
    const [loading, setLoading] = useState<boolean>(false);
    const handlePayment = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setLoading(true);
            try {
                const response = await axios.post('https://dev-api.sermyble.com/order/customer', {
                    foodCourtId: id,
                    items: data,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.paymentUrl) {
                    dispatch({ type: 'CLEAR_CART' });
                    navigate('/loading', { state: { paymentUrl: response.data.paymentUrl } });
                } else {
                    console.error('Failed to initiate payment');
                }
            } catch (error) {
                console.error('Error initiating payment', error);
            }
        }
    };

    const formatTime = (): string => {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div style={{ position: 'relative' }}>
            {isLoading && (
                <div className="loading-overlay">
                    <Loader />
                </div>
            )}
            {loading && (
                <div className="loading-overlay">
                    <Loader />
                </div>
            )}
            <div className='main'>
                <img src={foodimage} alt="Food Image" style={{ width: "100%", height: "auto" }} />
                <div className='first-div'>
                    <div className={`verify-div ${inputError ? 'input-error' : ''}`}>
                        <h3 style={{ color: "#84EEFC", fontWeight: 700, letterSpacing: "0.3px", fontSize: "18px" }}>Verify</h3>
                        <p style={{ color: "white", textAlign: "center", fontSize: "14px", fontWeight: 400, lineHeight: "26px", marginTop: "10px" }}>
                            Please enter the 6-digit code sent to you <br />+91 {phoneNumber}
                        </p>
                        <OtpInput
                            value={otp}
                            onChange={(otp) => {
                                setOtp(otp);
                                if (otp.length === 6) {
                                    setVerifyTrigger(true); // Automatically trigger OTP verification
                                }
                            }}
                            numInputs={6}
                            shouldAutoFocus
                            inputStyle={{
                                width: '34px',
                                height: '40px',
                                marginRight: "10px",
                                marginTop: "10px",
                                fontSize: '16px',
                                borderRadius: '6px',
                                padding: "2px",
                                border: `1px solid ${inputError ? 'red' : (correct ? 'green' : 'white')}`,
                                backgroundColor: "transparent",
                                color: "white"
                            }}
                            renderInput={(props) => (
                                <input
                                    {...props}
                                    type="tel"
                                    inputMode="numeric"  // Ensures numeric keypad is displayed
                                    pattern="[0-9]*"     // Ensures numeric input only
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (otp.length === 6) {
                                                setVerifyTrigger(true); // Trigger OTP verification on "Enter"
                                            }
                                        }
                                    }}
                                />
                            )}
                        />

                        {inputError && <p className="error-message" style={{ color: "red", marginTop: "10px" }}>Invalid OTP, please try again.</p>}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "flex-end" }}>
                        {timer === 0 && <p style={{ color: "white" }}>Didn't get code? </p>}
                        <button
                            disabled={timer > 0}
                            onClick={handleResendCode}
                            style={{
                                color: timer > 0 ? "gray" : "#90DFE9",
                                backgroundColor: "inherit",
                                fontSize: "14px",
                                height: "fit-content",
                                border: "none"
                            }}
                        >
                            Resend Code
                        </button>
                        {timer > 0 && <span style={{ color: "white", border: "none" }}>{formatTime()}</span>}
                    </div>
                    <button
                        onClick={() => setVerifyTrigger(true)}
                        disabled={otp.length !== 6 || isLoading}
                        className='buttons'
                    >
                        Verify and Proceed
                    </button>
                    <p className='help'>Help?</p>
                </div>
            </div>
            {showNotificationPermission && (
                <NotificationPermission
                    onAllow={requestNotificationPermission}
                    onDecline={() => {
                        setShowNotificationPermission(false)
                        if (loc) {
                            handlePayment();
                        } else {
                            setTimeout(() => navigate(`/${foodcourtID}/home`, { state: { user: code?.newUser } }), 2000);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default VerifyOtp;
