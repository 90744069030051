import FloatingIcon from '../../components/buttons/FloatingIcon';
import AccordionTransition from '../../components/misc/Accordion';
import Header from '../../components/misc/Header';
import { useFoodcourt } from '../../hooks/foodcourt/useFoodcourt';
import { useCategories } from '../../hooks/home/usehome';
import './Menu.css';
import search from '../../assets/images/search.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useCart } from '../../context/cart/carttcontext';
import arrow from '../../assets/icons/Arrow.png';
import Footer from '../../components/misc/Footer';
import OrderStatusCard from '../../components/cards/OrdeStatusCard';
import { useMenu } from '../../hooks/menu/useMenu';

const Menu: React.FC = () => {
  const location = useLocation();
  const { value: name } = location.state || {};
  const navigate = useNavigate();

  // Retrieve foodcourtID from localStorage
  const categoryId = localStorage.getItem("foodcourtID");
  const { data, isLoading, error } = useCategories(String(categoryId));
  const [searchTerm, setSearchTerm] = useState('');
  const { cart } = useCart();
  const { data: menu } = useMenu(String(categoryId));
  console.log("menu", menu);
  const menuItemIds = menu?.categories?.flatMap((category: any) =>
    category.menuItems.map((menuItem: any) => menuItem.menuItemId)
  );

  // Filter cart items that belong to the current food court
  const validCartItems = cart.filter(item => menuItemIds?.includes(item.menuItemId));

  const totalItemsInCart = validCartItems?.reduce((acc, item) => acc + item.quantity, 0);

  console.log("count", totalItemsInCart, validCartItems);
  if (!categoryId || error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <p>Unable to load menu. Please try again later.</p>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
      <Header title={data ? data?.foodCourtName : 'Food Court'} shown={false} onFilterClick={undefined} back={true} />
      <div className="container2">
        <div style={{ position: "sticky", top: 70, zIndex: 1000, backgroundColor: '#fff', width: '100%', borderBottom: "1px solid #DCDCDC" }}>
          <div className='outline'>
            <img src={search} width={18} height={18} />
            <input
              type='text'
              placeholder='Search for food...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </div>
        <AccordionTransition name={name} searchterm={searchTerm} />
        <div style={{ paddingInline: "10px" }}>
          <Footer />
        </div>
        {localStorage.getItem("authToken") && totalItemsInCart > 0 ? (
          <div style={{ position: "absolute", bottom: "150px" }}>
            <OrderStatusCard bottom='100px' />
          </div>
        ) : <OrderStatusCard bottom='54px' />}
      </div>
      <div style={{ position: "fixed", bottom: "105px", zIndex: "1000", right: "0px" }}>
        <FloatingIcon />
      </div>
      {totalItemsInCart > 0 ? (
        <div style={{ position: "fixed", bottom: "54px", zIndex: "1000", width: "100%" }} onClick={() => { navigate(`/${categoryId}/cart`) }}>
          <div className="arrow-animate" style={{ backgroundColor: "#1E3966", borderRadius: "3px", paddingBlock: "14px", marginInline: "1px", paddingInline: "30px", alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "20px" }}>
            <p style={{ color: "white", fontSize: "16px", fontWeight: "500", fontFamily: "sans-serif" }}>
              {totalItemsInCart > 1 ? `${totalItemsInCart} items are in cart` : `${totalItemsInCart} item is in cart`}
            </p>
            <img src={arrow} alt="" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Menu;
