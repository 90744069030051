import React, { useEffect, useState } from 'react';
import "./Profile.css";
import { Card, Button } from '@mui/material';
import pencil from '../../assets/icons/pencil.png';
import call_icon from '../../assets/icons/call_icon.png';
import mail_icon from '../../assets/icons/mail_icon.png';
import profile_icon from '../../assets/icons/profile_icon.png';
import Header from '../../components/misc/Header';
import { useProfile } from '../../hooks/profile/useProfile';
import Loader from '../../components/misc/Loader';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
const Profile = () => {
    const token = localStorage.getItem('authToken');
    const { data, isLoading, error, updateprofile } = useProfile(String(token));
    const [editData, setEditData] = useState({
        firstName: '',
        mobileNumber: '',
        email: ''
    });
    const [editable, setEditable] = useState({
        firstName: false,
        email: false
    });

    useEffect(() => {
        if (data) {
            setEditData({
                firstName: data.firstName || '',
                mobileNumber: data.mobileNumber.toString() || '', // Ensure it's a string for input compatibility
                email: data.email || ''
            });
        }
    }, [data]);

    const handleChange = (field: keyof typeof editData, value: string) => {
        setEditData(prev => ({ ...prev, [field]: value }));
    };

    const toggleEdit = (field: keyof typeof editable) => {
        setEditable(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleSaveChanges = async () => {
        try {
            await updateprofile({
                firstName: editData.firstName,
                email: editData.email,
                // dateOfBirth: data?.dateOfBirth,
                // Add other fields as necessary
            });
            setEditable({
                firstName: false,
                email: false
            });
            alert('Profile updated successfully!');
        } catch (error) {
            console.error('Failed to update profile:', error);
            alert('Failed to update profile.');
        }
    };

    return (
        <>
            {isLoading ? (
                <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Loader />
                </div>
            ) : (
                <div className="container">
                    <Header title={"Profile"} shown={false} onFilterClick={undefined} back={true} />
                    <Card sx={{ backgroundColor: "white", margin: "10px", padding: "20px", height: "77vh", display: "flex", flexDirection: "column", gap: "20px", marginTop: "80px", paddingTop: "10px" }}>
                        <div className='input_div'>
                            <label>Full Name</label>
                            <div className="inputfeilds">
                               <PersonOutlineOutlinedIcon sx={{fontSize:"25px",color:"rgb(25, 118, 210)"}}/>
                                <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "10px", width: "100%", borderLeft: "0.5px solid #DCDCDC", alignItems: "center" }}>
                                    <input type='text' value={editData.firstName} onChange={(e) => handleChange('firstName', e.target.value)} placeholder='Enter your name' required disabled={!editable.firstName} style={{ width: "100%", padding: "1px", backgroundColor: "white" }} />
                                    <div className='pencil' onClick={() => toggleEdit('firstName')}>
                                        <img src={pencil} alt="edit" width={17} height={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='input_div'> 
                            <label>Phone Number</label>
                            <div className="inputfeilds" >
                                <PhoneOutlinedIcon sx={{fontSize:"20px",color:"rgb(25, 118, 210)"}}/>
                                <input type='tel' value={editData.mobileNumber} placeholder='Enter your phone number' disabled={true} style={{ borderLeft: "0.5px solid #DCDCDC", width: "60%", padding: "1px", backgroundColor: "white", paddingLeft: "10px" }} />
                            </div>
                        </div>
                        <div className='input_div'>
                            <label>Email Address</label>
                            <div className="inputfeilds">
                                <EmailOutlinedIcon sx={{fontSize:"20px",color:"rgb(25, 118, 210)"}}/>
                                <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", width: "100%", borderLeft: "0.5px solid #DCDCDC", alignItems: "center" }}>
                                    <input type='email' value={editData.email} onChange={(e) => handleChange('email', e.target.value)} placeholder='Enter your email' required disabled={!editable.email} style={{ width: "100%", padding: "1px", backgroundColor: "white" }} />
                                    <div className='pencil' onClick={() => toggleEdit('email')}>
                                        <img src={pencil} alt="edit" width={17} height={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Object.values(editable).some(Boolean) &&
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "flex-end" }}>
                                <button style={{ width: "80%", height: "50px", borderRadius: "30px", border: "none", backgroundColor: "#1E3966", color: "white", fontWeight: "600", fontFamily: "sans-serif", cursor: "pointer" }} onClick={handleSaveChanges}>Save Changes</button>
                            </div>
                        }
                    </Card>
                </div>
            )}
        </>
    );
};

export default Profile;