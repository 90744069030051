import React, { useEffect, useState, useCallback } from 'react';
import './home.css';
import { Box, Drawer, Backdrop } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import hamburger from "../../assets/icons/hamburger.png";
import back from "../../assets/icons/back2.png";
import profile from "../../assets/icons/restaurnent_profile.png";
import expand from "../../assets/icons/expand.png";
import search from '../../assets/images/search.png';
import filter from '../../assets/images/filter.png';
import CategoryCard from '../../components/cards/CategoryCard';
import { useCategories } from '../../hooks/home/usehome';
import { useFoodcourt } from '../../hooks/foodcourt/useFoodcourt';
import { updateprofile } from '../../services/api/profile/profileServices';
import { category } from '../../types/home/homeTypes';
import Loader from '../../components/misc/Loader';
import { useCart } from '../../context/cart/carttcontext';
import OrdeStatusCard from '../../components/cards/OrdeStatusCard';
import Footer from '../../components/misc/Footer';
const Home: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const location = useLocation();
  const idArray = location.pathname.split('/');
  const categoryId = idArray[1];
  const [email, setEmail] = useState<string>('');
  const token = localStorage.getItem('authToken');
  localStorage.setItem("foodcourtID", categoryId);
  const navigate = useNavigate();
  const { data: categories, isLoading } = useCategories(categoryId);
  console.log("data cat",categories);
  const toggleDrawer = useCallback((newOpen: boolean) => () => {
    setOpen(newOpen);
  }, []);

  const handleLogout = async () => {
    // Unsubscribe the user from push notifications
    //   try {
    //     const swRegistration = await navigator.serviceWorker.ready;
    //     const subscription = await swRegistration.pushManager.getSubscription();

    //     if (subscription) {
    //         // Unsubscribe on the client side
    //         await subscription.unsubscribe();

    //         // Send the unsubscription to the server to remove it from the database
    //         await axios.post('https://dev-api.sermyble.com/notifications/notifications/unsubscribe', {
    //             endpoint: subscription.endpoint,
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });

    //         console.log('User unsubscribed from notifications');
    //     }
    // } catch (error) {
    //     console.error('Failed to unsubscribe the user:', error);
    // }

    // Clear the auth token and navigate
    localStorage.removeItem('authToken');
    navigate(`/${categoryId}/home`, { replace: true });
  };

  const handleOpenModal = () => {
    setModal(true);
  };
  const loc = location.state?.loc;
  const handleUpdateProfile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateprofile({ firstName, email }, String(token));
      toast.success("Profile Added");
      setModal(false);
      navigate(`/${categoryId}/home`, { replace: true });
      if (loc) {
        // handlePayment();
        navigate(loc);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Failed to update profile");
    }
  };
  const { cart, dispatch } = useCart();
  const data = cart.map(item => ({
    menuItemId: item.menuItemId,
    priority: 1,
    quantity: item.quantity
  }));
  const [loading, setloading] = useState<boolean>(false);


  useEffect(() => {
    if (location.state?.user) {
      handleOpenModal();
    }
  }, [location]);



  const handleSearchClick = () => {
    navigate(`/${categoryId}/home/search`);
  };
  const tabs = ["Home", "Menu", "Profile", "My Orders"];
  const tab = ["Home", "Menu", "My Orders"];
  const DrawerList = (
    <Box sx={{ width: 320 }} role="presentation" onClick={toggleDrawer(false)}>
      <div style={{ backgroundColor: "white", width: "100%", height: "100vh" }}>
        <div style={{ padding: 20, paddingTop: 50, display: "flex", justifyContent: "space-between" }}>
          <img src={back} onClick={toggleDrawer(false)} width={20} height={20} alt="back" />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
            <img src={profile} width={45} height={45} alt="profile" />
            <h2 style={{ color: "#1E3966", fontSize: 18, fontWeight: 700, textTransform: "capitalize", marginTop: "5px" }}>
              {categories?.foodCourtName}
            </h2>
          </div>
          <div></div>
        </div>
        <div style={{ flexDirection: "column", display: "flex" }}>
          {(token ? tabs : tab)?.map((tab) => (
            <div key={tab}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 25px",
                alignItems: "center",
                borderTop: "1px solid #DCDCDC",
                padding: "10px 5px",
                borderBottom: tab === "My Orders" ? "1px solid #DCDCDC" : 0,
              }}
              onClick={() => { tab === "My Orders" ? navigate(`/${categoryId}/orders`) : navigate(`/${categoryId}/${tab}`) }}
            >
              <h4 style={{ color: "#333333" }}>{tab}</h4>
              <img src={expand} alt="expand" width={5} height={10} />
            </div>
          ))}
          <button
            style={{
              margin: "20px 0 0 20px",
              textAlign: "center",
              backgroundColor: "#1E3966",
              borderRadius: 48,
              width: 80,
              padding: 8,
              cursor: "pointer",
              color: "white",
              border: "none"
            }}
            onClick={token ? handleLogout : () => navigate("/otp")}
          >
            {token ? "Logout" : "Login"}
          </button>
        </div>
      </div>
    </Box>
  );

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <div className='top-div'>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>

        <div className='upper-div'>
          <img src={hamburger} width={24} height={20} onClick={toggleDrawer(true)} alt="hamburger" />
          <div className='welcome'>
            <h2>Welcome to</h2>
            <h1>{categories? categories?.foodCourtName : "Food Court"}</h1>
          </div>
        </div>
        <div className='lower-div'>
          <div className='search' onClick={handleSearchClick}>
            <img src={search} width={18} height={18} alt="search" />
            <input type='text' placeholder='Search for food...' />
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end" }}>
              <img src={filter} style={{ backgroundColor: "#1E3966", borderRadius: "60px", padding: "4px" }} alt="filter" />
            </div>
          </div>
          <div className='categories'>
            <h2>Categories</h2>
            <p>Choose your favorite dish you want to order!</p>
          </div>
          {isLoading ? (
            <div style={{ height: "35vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Loader />
            </div>
          ) : (
            <div style={{ marginTop: "15px", display: "flex", gap: "10px", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start" }}>
              {categories?.categories?.length === 0 ? (
                <div style={{ display: "flex", alignItems: "center", height: "40vh", alignContent: "center", justifyContent: "center", width: "500px" }}>
                  No Categories present
                </div>
              ) : (
                categories?.categories?.map((item: category) => (
                  <CategoryCard categoryName={item.categoryName} categoryImage={item.categoryImage} key={item.categoryId} />
                ))
              )}
            </div>
          )}
          <Footer />
        </div>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent', width: "100%" }} open={modal}>
        <div className="modal-backdrop" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", justifyContent: "flex-start", paddingTop: "120px", textAlign: "center" }} onClick={e => e.stopPropagation()}>
          <form onSubmit={handleUpdateProfile} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", textAlign: "center" }}>
            <h2 style={{ marginBottom: "30px", letterSpacing: "1px" }}>You're almost There!</h2>
            <input
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              required
              onChange={e => setFirstName(e.target.value)}
              style={{ height: "40px", borderRadius: "56px", border: "none", paddingLeft: "20px", width: "100%" }}
            />
            <button type="submit" style={{ backgroundColor: "#ff794d", padding: "12px 30px", borderRadius: "65px", border: "none", color: "white", fontWeight: 600, width: "100%", textAlign: "center", height: "50px", fontSize: "15px", fontFamily: "inherit" }}>
              Continue
            </button>
          </form>
        </div>
      </Backdrop>
      {token &&
        <OrdeStatusCard bottom='54px'/>
      }
    </>
  );
};

export default Home;
