import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, RadioGroup, FormControlLabel, Radio, FormControl, Select, MenuItem, Box, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SelectChangeEvent } from '@mui/material/Select';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface alertdialog{
  open:boolean;
  handleClose:()=>void;
}
const  AlertDialogSlide:React.FC<alertdialog>=({ open, handleClose })=> {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [status, setStatus] = useState('pending');  // Initialize status to 'pending'
  const theme = useTheme();

  const handleFilterChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.value);
  };

  const handleStatusChange = (event:SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const dialogStyles = {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    paddingLeft:20,
    paddingRight:10,
    width:260
  };

  const buttonStyles = {
    fontWeight: 'bold'
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle style={dialogStyles}>{"Filter By"}</DialogTitle>
      <DialogContent dividers style={dialogStyles}>
        <RadioGroup name="filter-group" value={selectedFilter} onChange={handleFilterChange}>
          <FormControlLabel value="dateRange" control={<Radio color="primary" />} label="Date Range" />
          <Box display="flex" alignItems="center">
            <FormControlLabel value="status" control={<Radio color="primary" />} label="Status" />
            {selectedFilter === 'status' && (
              <FormControl variant="outlined" size="small" style={{ marginLeft: 16 }}>
                <Select
                  value={status}
                  onChange={handleStatusChange}
                  displayEmpty
                  style={{ minWidth: 120 ,height:34}}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
          <FormControlLabel value="amount" control={<Radio color="primary" />} label="Amount" />
        </RadioGroup>
      </DialogContent>
      <DialogActions style={{...dialogStyles,display:"flex",justifyContent:"flex-end",flexDirection:"row",gap:"10px"}}>
        <Button onClick={handleClose} style={buttonStyles} color="warning" variant="text">Cancel</Button>
        <Button onClick={handleClose} variant="contained" color="success">Save</Button>
      </DialogActions>
    </Dialog>
  );
}
export default AlertDialogSlide;