import { useNavigate } from "react-router-dom";
import apiClient from "../client";
import { isTokenExpired } from "../../../components/misc/TokenExpiry";
interface status {
    id: string;
    status: string;
}
export const checkStatus = async (id: string, token: string): Promise<status> => {
    const response = await apiClient.get<status>(`/order/customer/check-payment-status/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}
export const FetchOngoing = async() : Promise<[]> => {
    const check = isTokenExpired(String(localStorage.getItem('authToken')));

    if (!check) {
        const response = await apiClient.get<[]>(`/order/customer/ongoing-orders`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } else {
        // navigate('/otp'); // Navigate to the OTP page if the token is expired
        return [];
    }
}