import React, { useEffect, useState } from 'react';
import cooking from '../../assets/icons/cooking.png';
import { useOngoing } from '../../hooks/cart/useOngoing';
import Marquee from 'react-fast-marquee';

// Define the type for each order
interface Ongoing {
    orderNumber: string;
    remainingItemsSummary: string;
}

interface StatusCardProps {
    bottom: string;
}

const OrderStatusCard: React.FC<StatusCardProps> = ({ bottom }) => {
    const { data, isLoading } = useOngoing();
    const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
    
    useEffect(() => {
        if (data && data.length > 0) {
            const interval = setInterval(() => {
                setCurrentOrderIndex((prevIndex) => (prevIndex + 1) % data.length);
            }, 7000); // Change every 7 seconds
            return () => clearInterval(interval);
        }
    }, [data]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const currentOrder: Ongoing = data ? data[currentOrderIndex] : { orderNumber: "", remainingItemsSummary: "" };

    if (!currentOrder?.orderNumber) {
        return null;
    }

    return (
        <>
            {data && (
                <div
                    style={{
                        position: "fixed",
                        borderRadius: "1px",
                        bottom: bottom, // Correctly apply the bottom value
                        zIndex: 1000,
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#1E3966",
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}
                >
                    <div
                        style={{
                            position: "sticky",
                            zIndex: 1000,
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "7px",
                            marginLeft: "10px"
                        }}
                    >
                        <img src={cooking} width={40} height={40} alt="Cooking" />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "80%",
                            alignItems: "center"
                        }}
                    >
                        <h4
                            style={{
                                textAlign: "center",
                                width: "100%",
                                color: "white",
                                paddingBlock: 2
                            }}
                        >
                            Order {currentOrder?.orderNumber}
                        </h4>
                        <div style={{ width: "100%", paddingBottom: "12px" }}>
                            <Marquee gradient={true} speed={70} gradientColor="#1A3166">
                                <p style={{ color: "white", textTransform: "capitalize" }}>
                                    {currentOrder?.remainingItemsSummary}
                                </p>
                            </Marquee>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderStatusCard;
