import { useQuery } from '@tanstack/react-query';
import { FetchOngoing } from '../../services/api/cart/checkStatus';
import { useNavigate } from 'react-router-dom';

export const useOngoing = () => { 
  const navigate = useNavigate();
  const { data, error, isLoading } = useQuery<[], Error>({
    queryKey: ['ongoing'],
    queryFn: FetchOngoing,
    refetchOnWindowFocus: false
  });

  return {
      data: data,
      error,
      isLoading
  }
}