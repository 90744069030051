import { useEffect } from "react";
import { useAuth } from "../../context/auth/Authcontext";
import apiClient, { setupAxiosInterceptors } from "../../services/api/client";
import SessionExpiredModal from "../modals/SessionExpiredModal";

const GlobalErrorHandler = () => {
    const { sessionExpired, setSessionExpired, logout } = useAuth();
  
    useEffect(() => {
        const onSessionExpired = () => {
            console.log("Session expired interceptor triggered.");
            setSessionExpired(true);  // Trigger the modal via state
            logout();  // Logout the user
        };
  
        setupAxiosInterceptors(apiClient, onSessionExpired);  // Ensure this is uncommented
    }, [logout, setSessionExpired]);
  
    const handleSessionModalClose = () => {
        setSessionExpired(false);
    };
  
    return sessionExpired ? <SessionExpiredModal open={sessionExpired} onClose={handleSessionModalClose} /> : null;
  };

  export default GlobalErrorHandler;