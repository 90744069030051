import back2 from '../../assets/icons/back.png';
import { useNavigate } from 'react-router-dom';
import single_filter from '../../assets/icons/single_filter.png';

interface header {
    title: string;
    shown: boolean;
    onFilterClick: any;
    back: boolean;
}
const Header: React.FC<header> = ({ title, shown = true, onFilterClick,back }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    return (
        <div className='upper_div'>
            {back ?
                <img src={back2} alt="" onClick={handleClick} />:
                <div>{""}<span> </span></div>
            }
            <h2>{title}</h2>
            {shown ?
                <button style={{ backgroundColor: "#1E3966", border: "none" }} onClick={onFilterClick}>
                    <img src={single_filter} alt="Filter" />
                </button>
                :
                <p style={{ color: "#1E3966" }}>-</p>
            }
        </div>
    );
}

export default Header;
