import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuCard from '../cards/MenuCard';
import { useMenu } from '../../hooks/menu/useMenu';
import Loader from './Loader';

interface AccordionProps {
    name: string;
    searchterm: string;
}

const AccordionTransition: React.FC<AccordionProps> = ({ name, searchterm }) => {
    const foodId = localStorage.getItem("foodcourtID");
    const { data, isLoading ,error} = useMenu(String(foodId));
   
    React.useEffect(() => {
        let scrollToId = null;
        data?.categories?.forEach(item => {
            if (item.categoryName.toLowerCase() === name?.toLowerCase()) {
                scrollToId = item.categoryId;
            }
        });

        if (scrollToId) {
            const element = document.getElementById(scrollToId + '-header');
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }, [name, data]);
    return (
        <>
            {isLoading ? (
                <div style={{ height: "60vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Loader />
                </div>
            ) : (
                <div style={{ width: "auto", marginTop: "10px", marginBottom: "155px", display: "flex", flexDirection: "column", marginLeft: "12px", marginRight: "12px" }}>
                    {data?.categories?.length === 0 ? (
                        <Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px' }}>
                            Nothing to show
                        </Typography>
                    ) : (
                        data?.categories
                            ?.filter(item => item.categoryName.toLowerCase().includes(searchterm.toLowerCase()))
                            .map((item) => {
                                if (item.menuItems.length > 0) {
                                    return (
                                        <Accordion key={item.categoryId} expanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${item.categoryId}-content`}
                                                id={`${item.categoryId}-header`}
                                            >
                                                <Typography sx={{ fontWeight: 700, fontFamily: "sans-serif",textTransform:"uppercase" }}>
                                                    {item.categoryName} ({item.menuItems.length})
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {item.menuItems?.map((detail) => (
                                                    <MenuCard
                                                        key={detail.menuItemId}
                                                        title={detail.itemName}
                                                        price={detail.price}
                                                        imageUrl={detail.imageUrl || 'defaultImagePath.jpg'}
                                                        menuItemId={detail.menuItemId}
                                                        available={detail.available}
                                                    />
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                }
                                return null; // Return null if the category has no menu items
                            })
                    )}
                </div>
            )}
        </>
    );
};

export default AccordionTransition;
