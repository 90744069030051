// src/pages/NotFound/NotFound.tsx
import React from 'react';

const NotFound: React.FC = () => {
    const id=localStorage.getItem("foodcourtID");
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <h2 style={styles.subheading}>Page Not Found</h2>
      <p style={styles.text}>
        Oops! The page you are looking for doesn't exist or has been moved.
      </p>
      <a href={`/${id}/home`} style={styles.homeLink}>
        Go Back to Home
      </a>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    textAlign: 'center' as 'center',
    padding: '20px',
  },
  heading: {
    fontSize: '6rem',
    color: '#333',
    margin: '0',
  },
  subheading: {
    fontSize: '2rem',
    color: '#555',
    margin: '10px 0',
  },
  text: {
    fontSize: '1.2rem',
    color: '#777',
    margin: '20px 0',
  },
  homeLink: {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
    border: '2px solid #007bff',
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
};


export default NotFound;
