import { useNavigate, useParams } from 'react-router-dom';
import OrderItems from '../../pages/Orders/OrderItems';
import ready from '../../assets/icons/ready.png';
import progress from '../../assets/icons/progress.png';
import { ORDERITEMS } from '../../types/orders/orderTypes';
import React from 'react';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
interface ordercard {
    orderid: string;
    timestamp: string;
    status: string;
    totalprice: number;
    items: ORDERITEMS[];
    uniqueOrderid: number;
    overallFeedbackScore: number;
}

const OrderCard: React.FC<ordercard> = ({ orderid, timestamp, status, items, totalprice, uniqueOrderid, overallFeedbackScore }) => {
    const id = 2;
    const navigate = useNavigate();
    const { categoryId } = useParams<{ categoryId: string }>();
    const [value, setValue] = React.useState<number | null>(0);
    const [hover, setHover] = React.useState(-1);

    return (
        <div className='order-card'>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "15px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <h3 style={{ color: "#073980" }} onClick={() => { navigate(`/${categoryId}/orders/OrderDetails/${orderid}`, { state: { id: orderid } }) }}>Order #{uniqueOrderid}</h3>
                    <p style={{ width: 200, fontSize: 16 }}>{timestamp}</p>
                </div>
                {status === "PAYMENT_INITIATED" || status === "PAYMENT_FAILED" ?
                    <img src={progress} alt="" width={40} height={40} />
                    :
                    <img src={ready} alt="" width={40} height={40} />
                }
            </div>
            <div style={{ padding: "10px" }}>
                {items.map((detail) => {
                    return (
                        <OrderItems key={detail.itemId} imagepath={detail.imageUrl} title={detail.menuItemName} price={detail.price} quantity={detail.quantity} status={detail.status} />
                    )
                })
                }
                <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", alignContent: "center", paddingTop: "10px", gap: "40px", paddingRight: "10px" }}>
                    {status === "COMPLETED" && overallFeedbackScore === 0.0 &&
                        <div style={{ width: "30%", alignItems: "center", display: "flex", flexDirection: "column", gap: "5px" }}>
                            <button style={{ backgroundColor: "#1E3966", paddingInline: "12px", paddingBlock: "6px", border: "1px solid", color: "white", borderRadius: "4px", whiteSpace: "nowrap" }}
                                onClick={() => { navigate(`/${categoryId}/orders/rating/${orderid}`, { state: { data: items } }) }}
                            >Rate Now</button>
                        </div>
                    }
                    {overallFeedbackScore > 0 ? (
                        <div style={{ width: "30%", alignItems: "center", display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center" }} 
                        onClick={() => { navigate(`/${categoryId}/orders/rating/${orderid}`) }}
                        >
                            <p style={{
                                color: overallFeedbackScore >= 4 ? "#03C03C" : overallFeedbackScore <2 ? "#E32636" : "#FFA500",
                                fontSize: "18px", fontWeight: "700"
                            }}>
                                {overallFeedbackScore.toFixed(2)}
                            </p>

                            {overallFeedbackScore >= 4 ? (
                                <RecommendIcon sx={{ color: '#03C03C' }} /> // Show green icon for score >= 4
                            ) : overallFeedbackScore <2 ? (
                                <ThumbDownAltIcon sx={{ color: '#E32636' }} /> // Show red icon for score == 1
                            ) : (
                                <RecommendIcon sx={{ color: '#FFA500' }} /> // Show orange icon for score 2 or 3
                            )}
                        </div>
                    ) : (
                        <div style={{ width: "30%", alignItems: "center", display: "flex", flexDirection: "column", gap: "5px" }}>
                            {""}
                        </div>
                    )}

                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between", width: "50%" }}>
                        <p style={{ color: "#333", fontSize: "14px", fontWeight: "700", whiteSpace: "nowrap" }}>Total Amount :</p>
                        <span style={{ color: "#1B9FCE", fontSize: "18px", fontWeight: "700" }}>₹{totalprice}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderCard