import Menu from '../../assets/icons/Menu.png';
import { useNavigate } from 'react-router-dom';

const FloatingIcon = () => {
    const navigate = useNavigate();
    const  handleClick=()=>{
        navigate("MenuItems");
    }
  return (
    <button style={{display:"flex",borderRadius:"60px",border:"none",alignItems:"center",justifyContent:"center",width:"40px" ,height:"40px",marginRight:"7px"}} onClick={handleClick}>
        <img src={Menu} alt="" width={75} height={75}/>
    </button>
  )
}

export default FloatingIcon