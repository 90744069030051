import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import logo from '../../../src/assets/icons/logo.svg';
import greylogo from '../../../src/assets/icons/grey_logo.svg';

const SimpleBottomNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idArray = location.pathname.split('/');
  const categoryId = idArray[1];

  // Determine the current tab based on the pathname
  const getValueFromPath = (path?: string) => {
    if (!path) return 0;
    if (path.toLowerCase() === 'menu') return 1;
    if (path.toLowerCase() === 'orders') return 2;
    if (path.toLowerCase() === 'profile') return 3;
    return 0;
  };

  // Set initial value based on the current path
  const [value, setValue] = React.useState<number>(getValueFromPath(idArray[2]));

  // Update value when location changes
  React.useEffect(() => {
    const currentTab = getValueFromPath(idArray[2]);
    if (value !== currentTab) {
      setValue(currentTab);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(`/${categoryId}/home`);
        break;
      case 1:
        navigate(`/${categoryId}/menu`);
        break;
      case 2:
        navigate(`/${categoryId}/orders`);
        break;
      case 3:
        navigate(`/${categoryId}/profile`);
        break;
      default:
        break;
    }
  };

  const token = localStorage.getItem('authToken');

  return (
    <Box sx={{ width: "100%", position: "fixed", bottom: 0 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction 
          label="Sermyble" 
          icon={<img src={value === 0 ? logo : greylogo} width={30} alt="Sermyble" />} 
        />
        <BottomNavigationAction 
          label="Menu" 
          icon={<RestaurantMenuOutlinedIcon color={value === 1 ? 'primary' : 'inherit'} />} 
        />
        <BottomNavigationAction 
          label="Orders" 
          icon={<AssignmentOutlinedIcon color={value === 2 ? 'primary' : 'inherit'} />} 
        />
        {token && (
          <BottomNavigationAction 
            label="Profile" 
            icon={<PermIdentityOutlinedIcon color={value === 3 ? 'primary' : 'inherit'} />} 
          />
        )}
      </BottomNavigation>
    </Box>
  );
}

export default SimpleBottomNavigation;
