import { useQuery } from '@tanstack/react-query';
import { getFeedback } from '../../services/api/rating/ratingService';
import { FeedbackOrder } from '../../types/rating/ratingTypes';

export const useRating = (id: string) => { 
    const { data, error, isLoading } = useQuery<FeedbackOrder, Error>({
      queryKey: ['rating', id],
      queryFn: () => getFeedback(id),
      refetchOnWindowFocus: false
    });

    return {
        data: data,
        error,
        isLoading
    }
}