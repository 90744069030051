import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useStatus } from '../../hooks/cart/useStatus';

const OrderPending: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const idArray = location.pathname.split('/');
    const categoryId = idArray[1];
    const id = idArray[3];
    const token = localStorage.getItem('authToken');

    const totalTime = 120; // Total time in seconds
    const [timeLeft, setTimeLeft] = React.useState(totalTime);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTimeLeft) => {
                if (prevTimeLeft <= 1) {
                    clearInterval(timer);
                    navigate(`/${categoryId}/processingOrder/${id}`); // Navigate when time reaches zero
                }
                return prevTimeLeft > 0 ? prevTimeLeft - 1 : 0;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [categoryId, id, navigate]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const progress = (timeLeft / totalTime) * 100;

    return (
        <div className='processing_div' style={{ backgroundColor: 'white', textAlign: 'center', padding: '20px', display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <CircularProgress variant="determinate" value={progress} size={80} sx={{ color: '#ffdf00' }} />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '16px', color: 'gray' }}>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds} <br />
                    <span style={{ fontSize: '12px' }}>mins</span>
                </div>
            </div>
            <p style={{ fontSize: '16px', color: '#989898', marginTop: '20px', fontWeight: "700" }}>
                Please wait<br /> Getting you an update shortly..
            </p>
            <div style={{ borderTop: "1px solid #BDBDBD", marginInline: "40px", marginBlock: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px" }}>
                <p style={{ color: "#7C7C7C", fontSize: "15px" }}>Transaction Id</p>
                <p style={{ color: "#7C7C7C", fontSize: "15px" }}>{id}</p>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", marginInline: "20px", marginBlock: "5px", padding: "10px" }}>
                <p style={{ color: "#999", fontSize: "15px" }}>
                    Payment is in progress - please do not press back
                </p>
            </div>
        </div>
    );
};

export default OrderPending;
