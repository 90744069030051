import apiClient from "../client";
interface failed {
    id: string;
    paymentUrl: string;
}

export const createFailedOrder = async (id: string): Promise<failed> => {
    const response = await apiClient.post<failed>(`/order/customer/retry-payment/${id}`,null, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
    });
    return response.data;
}