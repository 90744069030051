import { ORDER, ORDERITEMS } from "../../../types/orders/orderTypes";
import { FeedbackOrder } from "../../../types/rating/ratingTypes";
import apiClient from "../client";

export const postFeedback = async(orderId:string,data:any): Promise<ORDERITEMS> =>  {
    const response = await apiClient.post<ORDERITEMS>(`/order/customer/${orderId}/feedback`,data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
    });
    return response.data;
}

export const getFeedback = async(orderId:string): Promise<FeedbackOrder> =>  {
    const response = await apiClient.get<FeedbackOrder>(`/customer/customer/feedback_by_order/${orderId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
    });
    return response.data;
}