import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://dev-api.sermyble.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor function modified to accept an Axios instance
export const setupAxiosInterceptors = (apiClient: { interceptors: { response: { use: (arg0: (response: any) => any, arg1: (error: any) => Promise<never>) => void; }; }; }, onSessionExpired: () => void) => {
    apiClient.interceptors.response.use(
        response => response,
        error => {
            console.log("Interceptor error response:", error.response);
            if (error.response && error.response.status === 401) {
                console.log("Detected 401 error, triggering session expired");
                onSessionExpired();
            }
            return Promise.reject(error);
        }
    );
};

export default apiClient;
