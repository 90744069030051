import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../OTPScreen/style.css";
import foodimage from "../../assets/images/mainlogo.png";
import logo from "../../assets/images/logo.png";
import { useOtp } from '../../hooks/otp/useOtp';
import Loader from '../../components/misc/Loader';

const OTPPage: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const initialPhoneNumber = location.state?.call;
  const loc = location.state?.from; // Determine if the user came from the Cart page
  const id = location.state?.id; // Get the food court ID
  const { otp, error, isLoading } = useOtp(phoneNumber, isButtonClicked);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (/^[0-9\b]*$/.test(value) && value.length <= 10) {
      setPhoneNumber(value);
      setIsButtonClicked(false);
      if (value.length === 10) {
        setPhoneNumber(value);
        sendOTP(value);
      }
    }
  };

  const sendOTP = (value: string) => {
    if (value.length === 10) {
      setIsButtonClicked(true);
    }
  };

  useEffect(() => {
    if (otp) {
      setIsButtonClicked(false);
      // Pass the location and ID to the VerifyOtp screen
      navigate('/verify-otp', loc ? { state: { phone: phoneNumber, loc: loc, id: id, from: loc },replace:true } : { state: { phone: phoneNumber, from: loc },replace:true });
    }
  }, [otp, navigate, phoneNumber, loc, id, loc]);

  useEffect(() => {
    if (error && isButtonClicked) {
      console.error("Error sending OTP:", error);
      setIsButtonClicked(false);
    }
  }, [error, isButtonClicked]);

  useEffect(() => {
    if (initialPhoneNumber) {
      setPhoneNumber(initialPhoneNumber);
      sendOTP(initialPhoneNumber);
    }
  }, [initialPhoneNumber]);

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <div className="main">
        <img src={foodimage} alt="Food Background" height="40%" width="100%" />
        <div className="first-div">
          <img src={logo} alt="Logo" />
          <div className="phno-div">
            <span>+91</span>
            <input
              type="tel"
              placeholder="Enter phone number"
              className="phno"
              value={phoneNumber}
              onChange={handleInputChange}
              maxLength={10}
              required
              autoFocus
            />
          </div>
          <button
            onClick={() => sendOTP(phoneNumber)}
            disabled={phoneNumber.length !== 10 || isLoading}
            className="buttons"
          >
            {isLoading ? "Sending..." : "Send OTP"}
          </button>
          <p className="help">Help?</p>
        </div>
      </div>
    </div>
  )
}

export default OTPPage;
