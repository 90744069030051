import React from 'react';

const RestrictedAccess: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Access Restricted</h1>
      <p>This application is only accessible from mobile devices.</p>
    </div>
  );
};

export default RestrictedAccess;
