import { useState } from 'react'
import placeholder from '../../assets/images/placeholder.png';
import "../Styles/card.css";
import { useCart } from '../../context/cart/carttcontext';
interface cartcard {
  imageUrl: string;
  title: string;
  subtext: string;
  price: number;
  quantity: number;
}
const CartCard: React.FC<cartcard> = ({ title, subtext, price, imageUrl, quantity }) => {
  const [count, setCount] = useState(quantity);
  const [image, setimage] = useState(imageUrl);
  const { dispatch } = useCart();
  const handleIncrease = () => {
    setCount(prevCount => prevCount + 1);
    dispatch({type:'INCREASE',title});
  };

  const handleDecrease = () => {
    setCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));
    if(quantity==1){
      handleClick();
    }
    dispatch({type:'DECREASE',title});
  };
  const handleError = () => {
    setimage(placeholder);
  }
  const handleClick = () => {
    dispatch({ type: 'REMOVE_ITEM', title });

  }
  return (
    <div style={{ backgroundColor: "white", margin: "10px", padding: "10px", display: "flex", flexDirection: "row", gap: "20px", borderRadius: "5px", height: "150px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
        <div>
          <img src={image} alt="" width="100px" height="80px" style={{ borderRadius: "8px" }} onError={handleError} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-around", width: "100%", gap: "0px" }}>
          <button onClick={handleDecrease} style={{ width: "20px", height: "20px", backgroundColor: "#1E3966", borderRadius: "60px", color: "white", border: "none", fontWeight: 500, textAlign: "center" }}>-</button>
          <span style={{ margin: '0 10px' }}>{quantity}</span>
          <button onClick={handleIncrease} style={{ width: "20px", height: "20px", backgroundColor: "#1E3966", borderRadius: "60px", color: "white", border: "none", fontWeight: 500, textAlign: "center" }}>+</button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0px", width: "70%",paddingBottom:"5px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <h2 style={{ fontSize: "19px", fontWeight: "700", color: "#1E3966", textTransform: "capitalize" }}>{title}</h2>
          <h4 style={{ color: "#1B9FCE", fontSize: "18px", fontWeight: "700" }}>₹{price}.00</h4>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", marginTop: "5px", gap: "6px", alignItems: "flex-end", height: "30px",width:"auto" }}>
          <button className="remove-btn" onClick={handleClick}>Remove</button>
          {/* <button style={{ border: "1px solid #1B9FCE", borderRadius: "3px", padding: "3px", paddingInline: "8px", backgroundColor: "#1B9FCE", color: "white", cursor: "pointer" }} disabled>Customise</button> */}
          <button style={{ border: "none", borderRadius: "3px", padding: "3px", paddingInline: "8px", backgroundColor: "#DFDFDF", color: "white" }} disabled>Customise</button>
        </div>
      </div>
    </div>
  )
}

export default CartCard