import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCategories } from '../../services/api/home/homeServices';
import { categories } from '../../types/home/homeTypes';

export const useCategories = (id: string) => { 
    const { data, error, isLoading } = useQuery<categories, Error>({
      queryKey: ['home', id],
      queryFn: () => fetchCategories(id),
      refetchOnWindowFocus: false
    });

    return {
        data: data,
        error,
        isLoading
    }
}