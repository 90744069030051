import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchOtp, resendOtp, verifyCode } from '../../services/api/otp/otpServices';
import otp from '../../types/otp/otpTypes';


export const useOtp = (phoneNumber: string, isEnabled: boolean) => {
    const queryClient = useQueryClient(); 
    const { data, error, isLoading } = useQuery<otp, Error>({
      queryKey: ['otp', phoneNumber],
      queryFn: () => fetchOtp(phoneNumber),
      enabled: isEnabled, 
    });

    return {
        otp: data,
        error,
        isLoading
    };
}

export const useCode= (phoneNumber:string,code:string,apiCalled:boolean)=>{
    const { data, error, isLoading } = useQuery<otp, Error>({
        queryKey: ['code', code],
        queryFn: () => verifyCode(phoneNumber,code),
        enabled:apiCalled,
        refetchOnWindowFocus: false
      });
  
      return {
          code: data,
          error,
          isLoading
      };
}

export const useResend = (phoneNumber: string, isEnabled: boolean) => {
    const { data, error, isLoading } = useQuery<otp, Error>({
      queryKey: ['resend', phoneNumber],
      queryFn: () => resendOtp(phoneNumber),
      enabled: isEnabled, 
      refetchOnWindowFocus: false
    });

    return {
        resend: data,
        error,
        isLoading
    };
}
