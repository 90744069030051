import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Header from '../../components/misc/Header';
import { postFeedback } from '../../services/api/rating/ratingService';
import { useEachOrder } from '../../hooks/orders/useOrder';
import { useRating } from '../../hooks/rating/useRating';
import Loader from '../../components/misc/Loader';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const MainOrderPage: React.FC = () => {
    const navigate = useNavigate();
    const { id, categoryId } = useParams<{ id: string; categoryId: string }>();
    const token = localStorage.getItem('authToken');
    const { data, isLoading, error } = useEachOrder(String(token), String(id));
    const { data: rating, isLoading: loader } = useRating(String(id));
    const [overallRating, setOverallRating] = useState<number | null>(null);
    const [overallComment, setOverallComment] = useState('');
    const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});

    useEffect(() => {
        if (data && data.items) {
            const initialRatings = data.items.reduce((acc, item) => ({
                ...acc,
                [item.itemId]: null
            }), {});
            setRatings(initialRatings);
        }
    }, [data]);

    const handleRatingChange = (itemId: string, newValue: number | null) => {
        setRatings(prev => ({
            ...prev,
            [itemId]: newValue
        }));
    };

    const handleFeedback = async () => {
        if (!data || !data.items) return;

        const itemFeedbacks = data.items.map(item => ({
            orderItemId: item.itemId,
            rating: ratings[item.itemId] ?? 0
        }));

        const feedbackData = {
            overallComment,
            overallRating,
            itemFeedbacks
        };

        try {
            const response = await postFeedback(String(id), feedbackData);
            if (response) {
                navigate(`/${categoryId}/orders`);
            }
        } catch (e) {
            console.error('Failed to submit feedback:', e);
        }
    };

    const isSubmitEnabled = overallRating != null || Object.values(ratings).some(rating => rating != null);

    if (!rating) {
        return <Loader />; // Handle case when data is undefined or empty
    }

    const overallrating = rating[0]?.rating; // Overall rating from the first object
    const overallcomment = rating[0]?.comment;

    // Extract item ratings from the rating data
    const itemRatings = rating.slice(1).map(item => ({
        score: item.rating,
        orderItemId: item.orderItemId // Ensure we have orderItemId for mapping
    }));

    return (
        <>
            {isLoading ? <Loader /> :
                <>
                    <Header title="Feedback" shown={false} onFilterClick={undefined} back={true} />
                    <div style={{ padding: '20px 40px', gap: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '#fff' }}>
                        <div style={{ maxWidth: '600px', textAlign: 'center', marginTop: '60px', width: '100%' }}>
                            <h1 style={{ marginBottom: '10px', color: '#1E3966', fontWeight: 'bold', fontSize: '24px' }}>Overall Experience</h1>
                            {rating.length > 0 ? (
                                <>
                                    <p style={{ marginBottom: '10px', color: '#4A5568', lineHeight: '1.5', fontSize: '16px' }}>
                                        Thank you for choosing our service.<br /> Below is your overall rating, reflecting your experience with us.
                                    </p>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 5 }}>
                                        <h2 style={{ fontSize: '30px', fontWeight: 'bold', color: overallrating >= 4 ? "#03C03C" : overallrating < 2 ? "#E32636" : "#FFA500", }}>{overallrating?.toFixed(2)}</h2>
                                        {overallrating >= 4 ? (
                                            <RecommendIcon sx={{ color: '#03C03C', fontSize: "30px" }} /> // Show green icon for score >= 4
                                        ) : overallrating < 2 ? (
                                            <ThumbDownAltIcon sx={{ color: '#E32636', fontSize: "30px" }} /> // Show red icon for score == 1
                                        ) : (
                                            <RecommendIcon sx={{ color: '#FFA500', fontSize: "30px" }} /> // Show orange icon for score 2 or 3
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p style={{ marginBottom: '10px', color: '#4A5568', lineHeight: '1.5', fontSize: '16px' }}>Thank you for choosing us! Please rate the items in your order and share any feedback.</p>
                                    <Rating
                                        name="overall-rating"
                                        value={overallRating}
                                        precision={0.5}
                                        onChange={(event, newValue) => setOverallRating(newValue)}
                                        size="large"
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                    />
                                </>
                            )}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', minHeight: '150px', marginBottom: "20px", width: "100%" }}>
                            {data && data.items && data.items.map(item => {
                                const itemRating = itemRatings.find(ratingItem => ratingItem.orderItemId === item.itemId);
                                return (
                                    <div key={item.itemId} style={{ maxWidth: '500px', marginBottom: '10px', backgroundColor: '#f7f7f7', padding: '10px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>{item.menuItemName}</p>
                                        {itemRating
                                            ?
                                            <div style={{ display: "flex", gap: "5px", alignItems: "center", }}>
                                                <p style={{ color: itemRating.score >= 4 ? "#03C03C" : itemRating.score < 2 ? "#E32636" : "#FFA500", fontWeight: "bold", fontSize: "20px" }}>
                                                    {itemRating.score}
                                                </p>
                                                {itemRating.score >= 4 ? (
                                                    <RecommendIcon sx={{ color: '#03C03C', fontSize: "22px", marginTop: "3px" }} /> // Show green icon for score >= 4
                                                ) : itemRating.score < 2 ? (
                                                    <ThumbDownAltIcon sx={{ color: '#E32636', fontSize: "22px", marginTop: "3px" }} /> // Show red icon for score == 1
                                                ) : (
                                                    <RecommendIcon sx={{ color: '#FFA500', fontSize: "22px", marginTop: "3px" }} /> // Show orange icon for score 2 or 3
                                                )}
                                            </div>
                                            :
                                            <Rating
                                                name={`item-rating-${item.itemId}`}
                                                value={ratings[item.itemId]}
                                                precision={0.5}
                                                onChange={(event, newValue) => handleRatingChange(item.itemId, newValue)}
                                                size="small"
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        }
                                    </div>
                                );
                            })}
                            <textarea
                                rows={5}
                                placeholder='Share your feedback'
                                value={overallcomment ? overallcomment : overallComment}
                                readOnly={!!overallcomment}
                                onChange={(event) => setOverallComment(event.target.value)}
                                style={{ width: '100%', maxWidth: '500px', backgroundColor: '#f7f7f7', borderRadius: '5px', padding: '10px', border: 'none', outline: 'none', resize: 'none', textTransform: "capitalize", fontSize: "16px", fontWeight: "700" }}
                            />
                        </div>
                        {rating.length===0 &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: window.innerHeight / 6 }}>
                                <button
                                    onClick={handleFeedback}
                                    disabled={!isSubmitEnabled}
                                    style={{
                                        width: '220px', backgroundColor: '#3182CE', color: 'white', padding: '15px 20px', borderRadius: '30px', fontSize: '16px', fontWeight: '600', cursor: 'pointer', boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)', transition: 'all 0.3s', outline: 'none',
                                        border: 'none',
                                        opacity: isSubmitEnabled ? 1 : 0.5
                                    }}
                                >
                                    {isLoading ? "Submitting" : "Submit Feedback"}
                                </button>
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default MainOrderPage;
