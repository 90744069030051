export const isTokenExpired = (token: string): boolean => {
    if (!token) return true; // If there's no token, consider it expired

    // Split the token into parts
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        // If the token doesn't have three parts, it's not a valid JWT
        return true;
    }

    try {
        // Decode the payload part of the token
        const payload = JSON.parse(atob(tokenParts[1]));

        // Get the current time (in seconds)
        const currentTime = Math.floor(Date.now() / 1000);

        // Check if the token has expired
        if (payload.exp && payload.exp < currentTime) {
            return true; // Token is expired
        } else {
            return false; // Token is still valid
        }
    } catch (error) {
        // If any error occurs during decoding, assume the token is invalid/expired
        return true;
    }
};