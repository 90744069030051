import getprofiles  from '../../../types/profile/profileTypes';
import updataprofiletype from '../../../types/profile/updateprofile';
import apiClient from '../client';

export const fetchprofile = async (token: string): Promise<getprofiles> => {
    const response = await apiClient.get<getprofiles>("/customer/customer/profile", {
        headers: {
            Authorization: `Bearer ${token}`, 
        }
    });
    return response.data;
}
export const updateprofile= async(profiledata: Partial<updataprofiletype>,token:string): Promise<updataprofiletype>=>{
    const response= await apiClient.put<updataprofiletype>("/customer/customer/update_customer",profiledata,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    });
    return response.data;
}