import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Splash/Splash.css";
import upvector from "../../assets/icons/up_vector.png";
import logo from "../../assets/icons/splash_logo.png";
import downvector from "../../assets/icons/down_vector.png";
import { useCategories } from '../../hooks/home/usehome';

const Splash: React.FC = () => {
    const navigate = useNavigate();
    // const { data: categories, isLoading } = useCategories("");
    // const foodcourtID = categories?.[0]?.foodCourtId;
    // useEffect(() => {
    //     if (foodcourtID) {
    //         const timer = setTimeout(() => {
    //             navigate(`/${foodcourtID}/home`);
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [navigate,foodcourtID]);

    return (
        <div className='main-div'>
            <img src={upvector} alt="upvector" width="150" height="150" />
            <img src={logo} alt="logo" style={{ marginLeft: 20, marginRight: 20 }} />
            <div style={{ justifyContent: "flex-end", display: 'flex' }}>
                <img src={downvector} alt="downvector" width="130" height="250" />
            </div>
        </div>
    );
};

export default Splash;
