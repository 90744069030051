import '../Styles/card.css';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../src/components/Styles/card.css';
import placeholder from '../../assets/images/placeholder.png';
import { useState } from 'react';

interface categoryprops {
  categoryName: string;
  categoryImage: string;
}
const CategoryCard: React.FC<categoryprops> = ({ categoryName, categoryImage }) => {
  const navigate = useNavigate();
  const id = 2;
  const { categoryId } = useParams<{ categoryId: string }>();
  const [imageSrc, setImageSrc] = useState(categoryImage);
  const handleError = () => {
    setImageSrc(placeholder);
  };
  return (
    <div className='categorycard' onClick={() => navigate(`/${categoryId}/menu`, { state: { value: categoryName } })}>
      <img src={imageSrc} alt="dosa"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }} onError={handleError} />
      <h2 style={{textTransform:"uppercase"}}>{categoryName}</h2>
    </div>
  )
}

export default CategoryCard