import apiClient from "../client";
import { cartData, CartOrder } from "../../../types/cart/cartTypes";

export const createOrder = async(categoryId: string, data: cartData, token: string): Promise<CartOrder> =>  {
    const response = await apiClient.post<CartOrder>(`/order/customer`, {
        foodCourtId: categoryId,
        items: data,
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}