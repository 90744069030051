import otp from '../../../types/otp/otpTypes';
import apiClient from '../client';


export const fetchOtp = async (phonenumber:string): Promise<otp> => {
    const response = await apiClient.post<otp>(`customer/customer/signup?mobileNumber=${phonenumber}`);
    return response.data;
  };
export const verifyCode=async (phonenumber:string,code:string):Promise<otp>=>{
    const response=await apiClient.post<otp>(`customer/customer/verify_otp?mobileNumber=${phonenumber}&otp=${code}`);
    return response.data;
}

export const resendOtp = async (phonenumber:string): Promise<otp> => {
    const response = await apiClient.post<otp>(`customer/customer/resend_otp?mobileNumber=${phonenumber}`);
    return response.data;
  };  

