import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import getprofiles from '../../types/profile/profileTypes';
import { fetchprofile, updateprofile } from '../../services/api/profile/profileServices';
import updataprofiletype from '../../types/profile/updateprofile';

export const useProfile = (token:string) => {
    const queryClient = useQueryClient();
    const { data, isLoading, error } = useQuery<getprofiles, Error>({
        queryKey: ['profile'],
        queryFn: () => fetchprofile(token),
        refetchOnWindowFocus: false
    });
    const mutation = useMutation<updataprofiletype, Error, Partial<updataprofiletype>>({
        mutationFn: (profiledata: Partial<updataprofiletype>) => updateprofile(profiledata,token),
        onSuccess: () => {
            // Invalidate the user query to refetch the updated data
            queryClient.invalidateQueries({ queryKey: ['profile'] });
        },
    });

    return {
        data: data,
        isLoading,
        error,
        updateprofile:mutation.mutate,
    }
}