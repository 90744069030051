import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';

const SessionExpiredModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleLoginRedirect = () => {
        onClose(); // Close the modal first
        navigate('/otp', { state: { from: location } });// navigate to otp page
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{"Session Expired"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your session has expired. Please log in again to continue.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLoginRedirect} color="primary">
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default SessionExpiredModal;
