import React, { createContext, useContext, useReducer, ReactNode, Dispatch, useEffect } from 'react';
import { CartItem, CartAction } from '../../types/cart/cartTypes'; // Adjust import path as necessary

interface CartProviderProps {
    children: ReactNode;
}

interface CartContextType {
    cart: CartItem[];
    dispatch: Dispatch<CartAction>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

// Helper function to load the state from localStorage
function loadState(): CartItem[] {
    const serializedState = localStorage.getItem('cart');
    if (serializedState === null) {
        return [];
    }
    return JSON.parse(serializedState);
}

// Helper function to save the state to localStorage
function saveState(state: CartItem[]): void {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cart', serializedState);
}

const cartReducer = (state: CartItem[], action: CartAction): CartItem[] => {
    let newState: CartItem[];
    switch (action.type) {
        case 'ADD_ITEM':
            const index = state.findIndex(item => item.title === action.item.title);
            if (index !== -1) {
                newState = [...state];
                newState[index].quantity += 1;
            } else {
                newState = [...state, { ...action.item, quantity: 1 }];
            }
            break;
        case 'REMOVE_ITEM':
            newState = state.filter(item => item.title !== action.title);
            break;
        case 'INCREASE':
            newState = state.map(item => item.title === action.title ? { ...item, quantity: item.quantity + 1 } : item);
            break;
        case 'DECREASE':
            newState = state.map(item => item.title === action.title ? { ...item, quantity: item.quantity - 1 } : item);
            break;
        case 'CLEAR_CART':
            newState = [];
            break;
        default:
            newState = state;
            break;
    }
    saveState(newState);
    return newState;
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
    const [cart, dispatch] = useReducer(cartReducer, loadState());

    // Effect to update localStorage when cart changes
    useEffect(() => {
        saveState(cart);
    }, [cart]);

    return (
        <CartContext.Provider value={{ cart, dispatch }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = (): CartContextType => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
