import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import LoadingPag from '../../components/misc/LoadingPag';
import { useStatus } from '../../hooks/cart/useStatus';
const ProcessingOrder: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const idArray = location.pathname.split('/');
    const categoryId = idArray[1];
    const id = idArray[3];
    const token = localStorage.getItem('authToken');
    const { data, isLoading, error } = useStatus(id!, token!);
    console.log("data in processing=>", data);
    React.useEffect(() => {
        if (!isLoading) {
            if (data?.status === "COMPLETED") {
                navigate(`/${categoryId}/orderSuccess/${id}`);
            } else if (data?.status === "PAYMENT_INITIATED") {
                navigate(`/${categoryId}/orderPending/${id}`);
            } else {
                navigate(`/${categoryId}/orderFailed/${id}`);
            }
        }
    }, [data, isLoading, navigate, categoryId, id]);

    return (
        <>
            {isLoading &&
                <LoadingPag />
            }
        </>
    )
}

export default ProcessingOrder