import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Splash from '../pages/Splash/Splash';
import OTPPage from '../pages/OTPScreen/OTPPage';
import VerifyOtp from '../pages/OTPScreen/VerifyOtp';
import Home from '../pages/Home/Home';
import Menu from '../pages/Menu/Menu';
import Orders from '../pages/Orders/Orders';
import Profile from '../pages/Profile/Profile';
import Cart from '../pages/Cart/Cart';
import MenuItems from '../pages/Menu/MenuItems';
import OrderDetails from '../pages/Orders/OrderDetails';
import BottomTabs from '../../src/components/misc/BottomTabs';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CartProvider } from '../context/cart/carttcontext';
import Search from '../components/misc/Search';
import { isMobileDevice } from '../utils/utils';
import RestrictedAccess from '../components/misc/RestrictedAccess';
import ProcessingOrder from '../pages/Orders/ProcessingOrder';
import LoadingPag from '../components/misc/LoadingPag';
import OrderSuccess from '../pages/Orders/OrderSuccess';
import OrderFailed from '../pages/Orders/OrderFailed';
import NotFound from '../pages/notfound/Notfound';
import MainOrderPage from '../pages/rating/MainOrderPage';
import { AuthProvider, useAuth } from '../context/auth/Authcontext';
import GlobalErrorHandler from '../components/misc/GlobalErrorHandler';
import OrderPending from '../pages/Orders/OrderPending';
import RatingScore from '../pages/rating/RatingScore';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { login } = useAuth();
  const location = useLocation();

  if (!login) {
    return <Navigate to="/otp" state={{ from: location }} replace />;
  }
  return children;
};

const AppRoutes: React.FC = () => {
  const isMobile = isMobileDevice();

  function BottomTabsConditional() {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);

    // Define a list of paths where the bottom tabs should be hidden
    const hideTabsForPaths = [
      'splash',
      'otp',
      'verify-otp',
      'cart',
      'processingOrder',
      'MenuItems',
      'orderSuccess',
      'orderFailed',
      '404',
      'rating',
      'orderPending',
      'ratingScore',
    ];

    // Check if any segment matches the paths to hide the bottom tabs
    const shouldHideTabs = pathSegments.some(segment => hideTabsForPaths.includes(segment));

    if (shouldHideTabs) {
      return null;
    }

    return <BottomTabs />;
  }

  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <GlobalErrorHandler />
          <div>
            {isMobile ? (
              <>
                <Routes>
                  <Route path="/" element={<Navigate to="/splash" replace />} />
                  <Route path="/splash" element={<Splash />} />
                  <Route path="/otp" element={<OTPPage />} />
                  <Route path="/verify-otp" element={<VerifyOtp />} />
                  <Route path="/loading" element={<LoadingPag />} />

                  {/* Public Route: Home can be accessed without token */}
                  <Route path="/:categoryId/home" element={<Home />} />

                  {/* Private Routes: Other routes require a token */}
                  <Route
                    path="/:categoryId/home/search"
                    element={
                      <PrivateRoute>
                        <Search />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/menu"
                    element={
                      // <PrivateRoute>
                      <Menu />
                      // </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orders"
                    element={
                      <PrivateRoute>
                        <Orders />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/profile"
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/cart"
                    element={
                      <PrivateRoute>
                        <Cart />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/processingOrder/:id"
                    element={
                      <PrivateRoute>
                        <ProcessingOrder />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orderSuccess/:id"
                    element={
                      <PrivateRoute>
                        <OrderSuccess />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orderFailed/:id"
                    element={
                      <PrivateRoute>
                        <OrderFailed />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orderPending/:id"
                    element={
                      <PrivateRoute>
                        <OrderPending />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/menu/MenuItems"
                    element={
                      <PrivateRoute>
                        <MenuItems />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orders/OrderDetails/:id"
                    element={
                      <PrivateRoute>
                        <OrderDetails />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:categoryId/orders/rating/:id"
                    element={
                      <PrivateRoute>
                        <MainOrderPage />
                      </PrivateRoute>
                    }
                  />
                   <Route
                    path="/:categoryId/orders/ratingScore/:id"
                    element={
                      <PrivateRoute>
                        <RatingScore />
                      </PrivateRoute>
                    }
                  />

                  {/* Catch-All Route */}
                  <Route path="*" element={<Navigate to="/404" replace />} />
                  <Route path="/404" element={<NotFound />} />
                </Routes>
                <BottomTabsConditional />
                <ToastContainer />
              </>
            ) : (
              <RestrictedAccess />
            )}
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
};

export default AppRoutes;
